// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

@narko-angle: -15deg;

html {
  position: relative;
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

.logo {
  position: relative;
  z-index: 1000;
  .make-lg-column-offset(1);
  @media (min-width: @grid-float-breakpoint) {
    margin-top: 15px;
  }
  @media (max-width: @grid-float-breakpoint-max) {
    display: block;
    position: absolute;
    width: 70%;
    max-width: 300px;
    z-index: -1;
    margin: 20px 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

h2, h2.block-title, .h2 {
  font-size: 24px;
  @media (min-width: @screen-md-min) {
    font-size: 30px;
  }
}
h1, .h1 {
  font-size: 42px;
  @media (min-width: @screen-md-min) {
    font-size: 72px;
  }
}


.btn {
  position: relative;
  z-index: 2;
  border: 0;
  text-transform: uppercase;
  background-color: transparent;
  &:hover, &:focus {
    background-color: transparent;
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 0px;
    left: -10px;
    right: -10px;
    height: 100%;
    -webkit-transform: skew(@narko-angle);
    -ms-transform: skew(@narko-angle);
    transform: skew(@narko-angle);
    z-index: -1;
  }
  &.btn-success, &.btn-primary {
    background-color: transparent;
    font-size: 24px;
    padding: 9px 30px;
    margin-left: 20px;
    margin-right: 20px;
    &:before {
      background-color: @btn-success-bg;
    }
    &:hover, &:focus {
      background-color: transparent;
      &:before {
        background-color: darken(@btn-success-bg, 5%);
      }
    }
  }
  &.btn-info {
    background-color: transparent;
    font-size: 17px;
    margin-left: 15px;
    margin-right: 15px;
    &:before {
      background-color: @btn-info-bg;
    }
    &:hover, &:focus {
      background-color: transparent;
      &:before {
        background-color: darken(@btn-info-bg, 10%);
      }
    }
  }
}

.more-link {
  text-align: center;
  margin-top: 30px;
  > a {
    .btn;
    .btn-success;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.carousel {
  .background-slide {
    min-height: ~"calc(100vh - 100px)";
    @media(min-width: @grid-float-breakpoint){
      min-height: ~"calc(100vh - 159px)";
    }
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    position: relative;
  }

  .blue-tint {
    .background-slide {
      &:before {
        content:"";
        display: block;
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom: 0;
        background-color: rgba(0,82,147,0.6);
      }
    }
  }

  .carousel-caption {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      bottom: auto;
      text-shadow: none;
      left: 0;
      right: 0;
      .container();
      margin: 0 auto;
      h1 {
        text-transform: uppercase;
      }
      .field-name-field-caption {
        font-size: 24px;
        margin-bottom: 30px;
        a {
          color: #fff;
        }
      }
  }
}

#block-locale-language, #block-search-form {
  position: relative;
  .pull-left;
  @media (min-width: @grid-float-breakpoint){
    .pull-right;
  }
}

.language-switcher-locale-url {
  list-style: none;
  margin: 0;
  padding-left: 15px;
  li {
    padding: 5px;
    list-style: none;
    display: inline-block;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  @media (min-width: @grid-float-breakpoint){
    padding: 0;
    background: #002166;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0px;
      right: 0px;
      height: 100%;
      background: #002166;
      -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-transform: skew(@narko-angle);
      -ms-transform: skew(@narko-angle);
      transform: skew(@narko-angle);
      z-index: 1;
    }
    li {
      list-style: none;
      display: inline-block;
      padding: 0;
      &.last {
        a.active:before {
          right: 11px;
        }
      }
      a {
        color: #fff;
        display: block;
        position: relative;
        z-index: 2;
        padding-left: 20px;
        padding-right: 20px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        margin-left: 10px;
        &.active {
          background: #004d8f;
        }
        &.active:before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: -10px;
          right: -10px;
          height: 100%;
          background: #004d8f;
          -webkit-transform: skew(@narko-angle);
          -ms-transform: skew(@narko-angle);
          transform: skew(@narko-angle);
          z-index: -1;
        }
      }
    }
  }
}
#page-header {
  position: relative;
}

.main-content {
  position: relative;
}
/* //old drawer
#drawer {
  .row();
  background-color: rgba(16,37,126,.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  .toggle-link {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
  #loc-drawer {
    max-width: 768px;
    margin: 0px auto;
    > div {
      margin: 30px auto;
    }
    .menu {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      @media(max-width: @screen-xs-max){
        flex-direction: column;
      }
      li {
        display: inline-block;
        flex: 0 1 auto;
        margin: 10px;
        a {
          color: #fff;
          text-transform: uppercase;
          text-decoration: none;
          padding-left: 85px;
          line-height: 46px;
          height: 46px;
          display: inline-block;
          background-size: 69px 46px;
        }
      }
    }
  }
  .collapsing {
    -webkit-transition: height .3s linear;
    -moz-transition: height .3s linear;
    -o-transition: height .3s linear;
    transition: height .3s linear;
  }
  .toggle-link {
    display: block;
    width: 100%;
    padding: 15px 0 5px;
    &.collapsed {
      .fa-angle-up {
        transform: rotate(180deg);
        -webkit-transition: all .1s linear;
        -moz-transition: all .1s linear;
        -o-transition: all .1s linear;
        transition: all .1s linear;
      }
    }
  }
}
*/

#drawer {
  .row();
  background-color: rgba(16,37,126,.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  .toggle-link {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
  #loc-drawer {
    max-width: 768px;
    margin: 0px auto;
    > div {
      margin: 15px auto;
    }
    .menu {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      @media(max-width: @screen-xs-max){
        flex-direction: column;
      }
      li {
        display: inline-block;
        flex: 0 1 auto;
        margin: 10px auto;
        &.active, &:hover, &:focus {
          border-bottom: 3px solid #fff;
        }
        a {
          color: #fff;
          text-transform: uppercase;
          text-decoration: none;
          padding-left: 50px;
          line-height: 26px;
          height: 26px;
          display: inline-block;
          background-size: 39px 26px;
          position: relative;
          margin-bottom: 5px;
          &.active, &:hover, &:focus {
            /*&:before {
              content: "";
              background: @brand-success;
              display: block;
              width: 47px;
              height: 34px;
              position: absolute;
              left: -4px;
              z-index: -1;
              top: -4px;
            }*/
          }
        }
      }
    }
  }
  .collapsing {
    -webkit-transition: height .3s linear;
    -moz-transition: height .3s linear;
    -o-transition: height .3s linear;
    transition: height .3s linear;
  }
  .toggle-link {
    display: block;
    width: 100%;
    padding: 15px 0 5px;
    &.collapsed {
      .fa-angle-up {
        transform: rotate(180deg);
        -webkit-transition: all .1s linear;
        -moz-transition: all .1s linear;
        -o-transition: all .1s linear;
        transition: all .1s linear;
      }
    }
  }
}



.region.region-footer {
  .row();
  background-color:#2a66b1;
  min-height: 400px;
}

.nopadding {
  padding-left: 0;
  padding-right: 0;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0;
  padding-top: 0px;
  @media (min-width: @screen-md-min){
    padding-top: 40px;
  }
  padding-bottom:0;
  color: #fff;
  overflow-x: hidden;
  &:after {
    content:"";
    display: table;
    clear: both;
  }
  border-top: 0;
  a {
    color: #fff;
  }
  .block-title {
    margin-top: 0;
    font-size: 20px;
    line-height: 2.3;
    margin-bottom: 0;
  }
  .menu {
    li a {
      @media (min-width: @screen-lg-min){
        font-size: 20px;
      }
    }
  }
}

#block-menu-block-1 {
  position: relative;
  z-index: 401;
  height: 400px;
  text-transform: uppercase;
  padding-top: 85px;
  &:before {
    content: "";
    position: absolute;
    display: block;
    top: -40px;
    left: 0px;
    @media (min-width: 1560px){
      left: -80px;
    }
    width: 200%;
    height: 100%;
    background-color: rgba(7,41,123,0.8);
    -webkit-transform: skew(@narko-angle);
    -ms-transform: skew(@narko-angle);
    transform: skew(@narko-angle);
    z-index: -1;
  }
  .menu {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    @media (min-width: 1560px){
      padding: 0;
    }
    > li {
      > a {
        font-weight: bold;
        text-decoration: none;
        line-height: 2.3;
      }
    }
  }
}
#block-bean-footer-block {
  div.contextual-links-wrapper {
    right: auto;
    left: 5px;
  }
  position: relative;
  z-index: 400;
  padding-top: 45px;
  padding-bottom: 45px;
  font-size: 12px;
  @media (min-width: @screen-sm-min){
    height: 400px;
    padding-top: 85px;
  }
  @media (min-width: @screen-lg-min) {
    font-size: 16px;
  }
  /*&:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: -80px;
    width: 500%;
    height: 100%;
    background: #2a66b1 url('../images/footer-bg.jpg?v=001');
    background-position: -200px center;
    background-repeat: no-repeat;
    -webkit-transform: skew(@narko-angle);
    -ms-transform: skew(@narko-angle);
    transform: skew(@narko-angle);
    z-index: -1;
  }*/

  .narko-skew {
    position: absolute;
    display: block;
    top: 0;
    left: -80px;
    width: 500%;
    height: 100%;
    background-position: -200px center;
    background-repeat: no-repeat;
    -webkit-transform: skew(@narko-angle);
    -ms-transform: skew(@narko-angle);
    transform: skew(@narko-angle);
    z-index: -1;
    overflow: hidden;
    .inner {
      position: absolute;
      display: block;
      top: 0;
      left: -80px;
      width: 500%;
      height: 100%;
      background: #2a66b1 url('../images/footer-bg.jpg?v=001');
      background-position: -200px center;
      background-repeat: no-repeat;
      -webkit-transform: skew(-@narko-angle);
      -ms-transform: skew(-@narko-angle);
      transform: skew(-@narko-angle);
    }
  }	
}

#block-bean-socialt {
  position: relative;
  z-index: 401;
  .entity {
    font-size: 40px;
  }
  height:400px;
  padding-top: 85px;
  @media (max-width: @screen-xs-max){
    height:200px;
    margin-top: 40px;
    font-size: 60px;
    padding-top: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      background-color: rgba(7,41,123,0.8);
      -webkit-transform: skew(@narko-angle);
      -ms-transform: skew(@narko-angle);
      transform: skew(@narko-angle);
      z-index: -1;
    }
  }
}

#navbar {
  position: absolute;
  width: 100%;
  z-index: 1000;
  font-size: 32px;
  .icon.glyphicon.glyphicon-search {
    font-size: 32px;
  }
  @media (min-width: @grid-float-breakpoint) {
    font-size: 18px;
    .icon.glyphicon.glyphicon-search {
      font-size: 16px;
    }
  }
  &.expanded:before {
    content: "";
    background-color: rgba(42,102,177,.95);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }
}
.navbar {
  padding: 0;
  margin: 0;
  border: 0;
  #block-search-form {
    margin: 0;
  }
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
  margin: 0;
}

.navbar-default {
  .navbar-toggle {
    position: absolute;
    right: 0;
    margin: 25px 0;
    border: none;
    background: #2a66b1 !important;
    border-radius: 0;
    z-index: 10000;
    &:hover {
      background: #2a66b1 !important;
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: -12px;
      width: 100%;
      height: 100%;
      background-color: #2a66b1;
      -webkit-transform: skew(@narko-angle);
      -ms-transform: skew(@narko-angle);
      transform: skew(@narko-angle);
      z-index: -1;
    }
    .icon-bar {
      width: 42px;
      height: 7px;
      transition: all 0.2s;
      background-color: #fff;
    }
  }
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 7px;
}

.navbar-default.expanded {
  .navbar-toggle {
    background: transparent !important;
    &:hover {
      background: transparent !important;
    }
    &:before {
      background-color: transparent;
    }
    .icon-bar {
      background-color: #fff;
    }
    .top-bar {
      transform: rotate(45deg);
      transform-origin: 3% 50%;
    }
    .middle-bar {
      opacity: 0;
    }
    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 3% 50%;
    }
  }
}

.navbar-collapse {
  padding: 0;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &.collapsing {
    border: 0;
  }
}

.navbar-header {
  @media (min-width: @grid-float-breakpoint){
    position: absolute;
    top:0;
    left: 0;
  }
}

.form-search {
  .form-actions {
    margin-bottom: 0;
  }
  .input-group {
      position: relative;
      > input {
        display: none;
      }
      .btn.btn-primary {
        background-color: transparent;
        border-color: transparent;
        &:before {
          content: none;
        }
      }
    @media (min-width: @grid-float-breakpoint){
      > input {
        display: table-cell;
      }
      &:before {
        content:"";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #bfbdbd;
        -webkit-transform: skew(@narko-angle);
        -ms-transform: skew(@narko-angle);
        transform: skew(@narko-angle);
        z-index: 1;
      }
      input[type="text"].form-control {
        padding: 29px;
        background-color: transparent;
        border-color: transparent;
        color:#fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-transform: uppercase;
        @media (min-width: @screen-lg-min){
          width: 280px;
        }
      }
      input[type="text"].form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color:#fff;
      }
      input[type="text"].form-control::-moz-placeholder { /* Firefox 19+ */
        color:#fff;
      }
      input[type="text"].form-control:-ms-input-placeholder { /* IE 10+ */
        color:#fff;
      }
      input[type="text"].form-control:-moz-placeholder { /* Firefox 18- */
        color:#fff;
      }
    }
  }
}


#block-bootstrap-megamenu-main-menu {
  clear: both;
  .caret {
    @media (max-width: @grid-float-breakpoint-max){
      border-top-color: #fff;
      margin-top: 12px;
    }
  }
}

.full-width-menu .mega-dropdown-inner {
  .container-fluid();
}

.bootstrap-megamenu .dropdown-menu {
  background-color: transparent;
  @media (min-width: @grid-float-breakpoint){
    background-color: rgba(16, 37, 126, 0.8);
  }
  color: #fff;
  a {
    color: #fff;
  }
}

.bootstrap-megamenu .caret {
  border-top: 6px solid #000;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.bootstrap-megamenu .dropdown-submenu {
  .dropdown-toggle { position: relative }
  .caret {

    display: inline-block;
    @media (min-width: @grid-float-breakpoint){
	transform: rotate(-90deg);
	filter: invert(1);
	position: absolute;
	right: 0;
    }
  }
  @media (min-width: @grid-float-breakpoint){
    > .dropdown-menu {
      left: 105%;
    }
  }
}
.bootstrap-megamenu .nav {
  > li {
    float: none;
    > a {
      font-weight: bold;
    }
  }
  .open {
   > a:hover {
     border-color: #fff;
   }
  }

  @media (min-width: @grid-float-breakpoint){
    text-align: right;
    padding-top: 36px;
    padding-bottom: 36px;
    > li {
      display: inline-block;
      > a {
        //padding: 36px 20px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  > li > a {
    text-transform: uppercase;
    color: #fff;
    @media (min-width: @grid-float-breakpoint){
      color: @brand-primary;
    }
  }
  > .active {
    > a {
      @media (min-width: @grid-float-breakpoint){
        color: @brand-success;
      }
    }
  }
  > li {
    > a {
      &:hover, &:focus {
        background-color: transparent;
        @media (min-width: @grid-float-breakpoint){
          color: @brand-success;
        }
      }
    }
  }
}

.bootstrap-megamenu-nav.nav .open > a, .bootstrap-megamenu-nav.nav .open > a:hover, .bootstrap-megamenu-nav.nav .open > a:focus {
  background-color: transparent;
}

.bootstrap-megamenu-submenu.dropdown-menu {
  @media (max-width: @grid-float-breakpoint-max){
    position: relative;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 0 none;
  }
  .mega-nav {
    li a {
      text-decoration: none;
      text-transform: uppercase;
      @media (max-width: @grid-float-breakpoint-max){
        margin-left: 0;
      }
    }
  }
}

@media (max-width: @grid-float-breakpoint-max){
  .bootstrap-megamenu .row, .bootstrap-megamenu .mega-dropdown-menu, .bootstrap-megamenu .row [class*="col-md-"] {
      width: 100% !important;
      min-width: 100% !important;
      left: 0 !important;
      margin-left: 0 !important;
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
  }
}


.view-after-sales-campaigns {
  position: relative;
  margin-top: 40px;
  background: #2f64b4 url('../images/callout-bg.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  @media (min-width: @screen-sm-min){
    display: flex;
  }
  &:after {
    content:"";
    display: table;
    clear: both;
  }
  .view-header {
    .make-xs-column(9);
    //min-height: 400px;
    @media (min-width: @screen-sm-min){
      .make-md-column(4);
    }
    position: relative;
    left: 0;
    top: -40px;
    max-width: 555px;
  }
  .view-content {
    .make-xs-column(12);
    @media (min-width: @screen-sm-min){
      .make-md-column(7);
      .make-md-column-offset(1);
      min-height: 400px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    .node {
      margin-bottom: 30px;

    }
    .item, .field-item {
      text-align: center;
      color: #fff;
      img {
        margin: 0 auto;
      }
    }
  }
  .entity-bean {
    .content {
      max-width: 385px;
      margin: 0 auto;
    }
    font-size: 16px;
    padding: 50px 0;
    @media (min-width: @screen-md-min){
      padding: 76px 15px 70px;
    }
    a {
      color: #fff;
    }
    color: #fff;
    position: relative;
    z-index: 500;
    &:before {
      position: absolute;
      display: block;
      top: 0;
      right: -30px;
      width: 200%;
      height: 100%;
      background-color: rgba(7,41,123,0.8);
      -webkit-transform: skew(@narko-angle);
      -ms-transform: skew(@narko-angle);
      transform: skew(@narko-angle);
      z-index: -1;
    }
    .field-name-title-field {
      text-transform: uppercase;
    }
  }
  .field-name-node-link {
    text-align: center;
    margin-top: 30px;
    a {
      .btn;
      .btn-info;
      color: #fff;
      &:hover, &:focus {
        color: #fff;
      }
    }
  }
}

.view-sales-location-map-small {
  position: relative;
  margin-top: 40px;
  background-color: #2f64b4;
  &:after {
    content:"";
    display: table;
    clear: both;
  }
  @media (min-width: @screen-sm-min){
    margin-bottom: 40px;
  }
  .leaflet-container {
      background-color: #2f64b4;
    @media (max-width: @screen-sm-max){
      height: 600px !important;
    }
  }
  .view-header {
    .make-xs-column(9);
    .make-md-column(4);
    position: absolute;
    left: 0;
    top: -40px;
    max-width: 555px;
  }
  .view-content {
    .make-xs-column(12);
    .nopadding;
  }

  .entity-bean {
    .content {
      max-width: 385px;
      margin: 0 auto;
    }
    font-size: 16px;
    padding: 50px 0px;
    @media (min-width: @screen-md-min){
      padding: 76px 15px 70px;
    }
    color: #fff;
    position: relative;
    z-index: 500;
    &:before {
      position: absolute;
      display: block;
      top: 0;
      right: -30px;
      width: 200%;
      height: 100%;
      background-color: rgba(7,41,123,0.8);
      -webkit-transform: skew(@narko-angle);
      -ms-transform: skew(@narko-angle);
      transform: skew(@narko-angle);
      z-index: -1;
    }
    .field-name-title-field {
      text-transform: uppercase;
    }
  }
}


.view-sales-locations-map, .view-after-sales-locations-map, .view-after-sales-map {
  position: relative;
  background-color: #2f64b4;
  overflow: auto;
  margin-bottom: 25px;
  @media(min-width: @screen-md-min){
    margin-bottom: 100px;
  }
  .view-header {
    .container();
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 500;
    margin: 0 auto;
  }
  .leaflet-container {
    background-color: #2f64b4;
    max-height: ~"calc(100vh - 100px)";
    @media(min-width: @grid-float-breakpoint){
      max-height: ~"calc(100vh - 159px)";
    }
  }
  .entity-bean {
    color: #fff;
    .content {
      max-width: 555px;
      font-size: 16px;
      @media(min-width: @grid-float-breakpoint){
        font-size: 24px;
      }
    }
    .block-title  {
      text-transform: uppercase;
      font-size: 38px;
      @media(min-width: @grid-float-breakpoint){
        font-size: 72px;
      }
    }
  }
}

/* Products, References */
.view-products,
.view-product-groups,
.view-references {
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;
  > .view-content > .views-row {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  &.view-display-id-block_2 {
    max-width: 1720px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 70px;
    .views-bootstrap-grid-plugin-style {
      > .row {
        > div {
          margin-bottom: 30px;
          &.col-lg-3 {
            @media (min-width: @screen-lg-min) {
              width: 20%;
            }
          }
          &.visible-lg-block {
            display: none !important;
          }
        }
      }
    }
  }
  &.view-display-id-block_1 {
    .row > div {
      text-align: center;
      .views-field-title-field {
        text-transform: uppercase;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .field-name-field-product-images {
    min-height: 100%;
    .field-items {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      .field-item {

      }
      .field-item:last-child {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .field-name-title-field {
    h4 {
      text-transform: uppercase;
      margin-top: 50px;
    }
  }
  .view-mode-text_left {
    .col-md-flex {
      margin: 15px 0;
      .view-id-product_images.view-display-id-entity_view_2 {
        .hidden-xs;
        .hidden-sm;
        .view-content {
          height: 100%;
          div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
      @media (min-width: @screen-md-min) {
        display: flex;
        justify-content: flex-end;
        .view-id-product_images.view-display-id-entity_view_2 {
          margin-right: 15px;
          margin-left: 15px;

        }
      }
    }
  }
  .view-mode-text_right {
    .col-md-flex {
        margin: 15px 0;
        .view-id-product_images.view-display-id-entity_view_2 {
        .hidden-xs;
        .hidden-sm;
        .view-content {
          height: 100%;
          div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
      @media (min-width: @screen-md-min) {
        display: flex;
        justify-content: flex-start;
        .view-id-product_images.view-display-id-entity_view_2 {
          margin-right: 15px;
          margin-left: 15px;

        }
      }
    }
  }
}

.node-reference.view-mode-full,
.node-product.view-mode-full,
.node-product-group.view-mode-full {
  .col-md-flex {
    margin: 15px 0;
    .view-id-product_images.view-display-id-entity_view_2 {
      .hidden-xs;
      .hidden-sm;
      .view-content {
        height: 100%;
        div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      display: flex;
      justify-content: flex-end;
      .view-id-product_images.view-display-id-entity_view_2 {
        margin-right: 15px;
        margin-left: 15px;

      }
    }
  }
}

.row.equal {
  @media (min-width: @screen-md-min) {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
}

.main-container {
  padding-top: 100px;
  @media (min-width: @grid-float-breakpoint){
    padding-top: 159px;
  }
  overflow-x: hidden;
  section {
  }
}

.paragraphs-items-field-slideshow {
  .row();
  .btn {
    z-index: 10;
  }
}

div.paragraphs-items.paragraphs-items-field-paragraphs {
  .field-name-field-paragraphs {
    > .field-items {
      > .field-item {
        margin-top: 70px;
        margin-bottom: 70px;
      }
    }
  }
}

div.entity.entity-paragraphs-item.paragraphs-item-media-bank {
  .field-name-field-paragraph-title {
    h3 {
      margin-top: 0;
    }
  }
  .field-name-field-paragraph-description {
    margin-bottom: 15px;

  }
  .field-name-field-paragraph-thumbnail {
    margin-bottom: 15px;
    @media (min-width: @screen-sm-min){
      float: right;
    }
  }
}


div.entity.entity-paragraphs-item.paragraphs-item-left-text-right-quote {
  .container();
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }
  @media (min-width: @screen-md-min) {
    > .row {
      display: inline-flex;
      align-items: center;
      > div:last-child {
        display: flex;
        justify-content: center;
      }
    }
  }
  .quote {
    background: url('../images/quote.png') no-repeat;
    background-position: 85% top;
    background-size: 150px;
  }
  .field-name-field-quote {
    font-size: 28px;
    color: @brand-success;
    max-width: 70%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
div.entity.entity-paragraphs-item.paragraphs-item-left-quote-right-text {
  .container();
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }
  @media (min-width: @screen-md-min) {
    > .row {
      display: inline-flex;
      align-items: center;
      > div:first-child {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .quote {
    background: url('../images/quote.png') no-repeat;
    background-position: 65% top;
    background-size: 150px;
  }
  .field-name-field-quote {
    font-size: 28px;
    color: @brand-success;
    max-width: 70%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}


div.entity.entity-paragraphs-item.paragraphs-item-callout {
  margin-top: 40px;
  .angle {
    .group-quote {
      position: relative;
      top:-40px;
      @media (max-width: @screen-xs-max){
        .make-xs-column(9);
      }
      max-width: 555px;
      z-index: 2;
      margin: 0 auto;
      color: #fff;
      font-size: 16px;
      padding: 40px 0;
      @media (min-width: @screen-md-min){
        padding: 76px 15px 70px;
      }
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        right: -30px;
        width: 200%;
        height: 100%;
        background-color: rgba(7,41,123,0.8);
        -webkit-transform: skew(@narko-angle);
        -ms-transform: skew(@narko-angle);
        transform: skew(@narko-angle);
        z-index: -1;
      }
      > div {
        max-width: 385px;
        margin: 0 auto;
      }
    }
  }
  padding: 0 15px;
  background: #2f64b4 url('../images/callout-bg.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  color: #fff;
  .field-name-field-quote {
    color: #fff;
  }
  .field-name-field-callout-title {
    text-transform: uppercase;
  }
  .callout-content {
    padding: 50px;
    @media (max-width: @screen-xs-max){
      clear: both;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    @media (min-width: @screen-sm-min){
      min-height: 400px;
    }
    > div {
      max-width: 1090px;
      margin: 0 auto;
    }
  }
  div.field.field-name-field-paragraph-text {
    margin-bottom: 30px;
  }
  div.field.field-name-field-action-link.field-type-link-field {
    text-align: center;
  }
}


.nav.nav-tabs {
  &.tabs-left {
    > li {
      margin-top: 5px;
      &:open {
        > a {
          background-color: @brand-primary;
          &:hover {
            background-color: @brand-primary;
          }
        }
      }
      > a {
        border: 1px solid #eeeeee;
        border-radius: 0;
        background-color: @brand-primary;
        color: #fff;
        &:before {
          color: #fff;
        }
        &.collapsed {
          background-color: #fff;
          color: @gray-dark;
          &:before {
            color: @brand-primary;
          }
        }
      }
    }
  }
}

.panel-heading {
  padding: 0;
  .panel-title {
    a {
      display: block;
      padding: 10px 15px;
      width: 100%;
      text-decoration: none;
    }
  }
}
#accordion {
  .panel {
    .views-row {
      padding: 30px;
      .field-name-field-address {
        .organisation-name {
          /*text-transform: uppercase;
          font-size: 24px;
          display: block;
          margin-bottom: 20px;*/
        }
      }
      .field-label {
        font-weight: normal;
      }
    }
    @media (min-width: @screen-md-min){
      .views-row {
        padding: 0 30px 60px;
      }
      border: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      .collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
      }
    }
  }
}

.view-facebook {
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 70px;
  .views-bootstrap-grid-plugin-style {
    > .row {
      > div {
        margin-bottom: 60px;
      }
      .visible-lg-block {
        display: none !important;
      }
    }
  }

  .col-lg-3 {
    @media(min-width: @screen-lg-min){
      width: 20%;
    }
  }
  .group-link.field-group-link {
    text-decoration: none;
    color: #005293;
  }
  .field{
    &.field-name-node-language {
      float: left;
      line-height: 50px;
      width: 50px;
      background: #005293;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      margin-right: 10px;
    }
    &.field-name-title-field {
      h4 {
        margin-top: 7px;
        margin-bottom: 0;
        color: #005293;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
    &.field-name-field-fb-story {
      clear: both;
    }
    &.field-name-post-date {
      margin-bottom: 30px;
      font-size: 12px;
    }
    &.field-name-field-fb-message {
      margin-bottom: 30px;
    }
  }
}


/* Carousel show many slide one */
.carousel-showmanymoveone
{
  &:hover {
    .carousel-control {
      @media (min-width: @grid-float-breakpoint){
        opacity: 1;
      }
    }
  }

  .carousel-control
  {
    @media (min-width: @grid-float-breakpoint){
      opacity: 0;
    }
    width: 4%;
    background-image:none;

    &.left
    {
      margin-left:15px;
    }

    &.right
    {
      margin-right:15px;
    }
  }

  .cloneditem-1,
  .cloneditem-2,
  .cloneditem-3
  {
    display: none;
  }

  .carousel-inner
  {
    @media all and (min-width: 768px)
    {
      @media (transform-3d), (-webkit-transform-3d)
      {
        > .item.active.right,
        > .item.next
        {
          transform: translate3d(50%, 0, 0);
          left: 0;
        }

        > .item.active.left,
        > .item.prev
        {
          transform: translate3d(-50%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active
        {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      > .active.left,
      > .prev
      {
        left: -50%;
      }

      > .active.right,
      > .next
      {
        left:  50%;
      }

      > .left,
      > .prev.right,
      > .active
      {
        left: 0;
      }

      .cloneditem-1
      {
        display: block;
      }
    }

    @media all and (min-width: 992px)
    {
      @media (transform-3d), (-webkit-transform-3d)
      {
        > .item.active.right,
        > .item.next
        {
          transform: translate3d(25%, 0, 0);
          left: 0;
        }

        > .item.active.left,
        > .item.prev
        {
          transform: translate3d(-25%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active
        {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      > .active.left,
      > .prev
      {
        left: -25%;
      }

      > .active.right,
      > .next
      {
        left:  25%;
      }

      > .left,
      > .prev.right,
      > .active
      {
        left: 0;
      }

      .cloneditem-2,
      .cloneditem-3
      {
        display: block;
      }
    }
  }
}

.field-name-field-directions {
  font-weight: bold;
  a {
    /*&:after {
      content: @fa-var-location-arrow;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
    }*/
  }
}

.page-user {
  #block-system-main {
    .container();
  }
}

.page-search {
  .main-container {
    .container();
    min-height: 500px;
  }
}

.md-margin-right-30 {
  @media (min-width: @screen-md-min){
    margin-right: 30px !important;
  }
}

div#cboxCurrent {
  display: none !important;
}

.leaflet-interactive {
  cursor: default;
}


.view-after-sales-locations {
  .panel {
    .views-row {
      .make-md-column(6);
      &.views-row-odd {
        clear:left;
      }
    }
  }
}

@-webkit-keyframes autofill {
    to {
        color: @text-color;
        background: transparent;
    }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: rgba(255,255,255,0) !important;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.btn:active,
.btn:focus,
.btn.active {
   outline:0 !important;
   background-image: none;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
   box-shadow: none;
}

#block-views-vacancies-block {
  margin-top: 100px;
  margin-bottom: 100px;
  .block-title {
    font-size: 40px;
  }
}

.node-vacancy {
  .field-name-body {
    margin-bottom: 30px;
  }
}

.view-vacancies {
  .field-name-node-link {
    margin: 0;
    text-align: center;
    display: inline-block;
    margin-bottom: 15px;
  }
  .field-name-field-apply {
    display: inline-block;
    text-align: center;
    margin-bottom: 15px;
  }
}

.paragraphs-item-link-block {
  .field-name-field-link {
    .field-items {
      .field-item {
        .make-sm-column(3);
        text-align: center;
        .btn {
          text-decoration: none;
          padding: 30px;
          font-size: @font-size-base;
        }
      }
    }
  }
}

.views-exposed-widget {
  &.views-widget-filter-title {
    clear: both;
  }
  &.views-submit-button {
    .btn.btn-info {
      background-color: rgba(181, 179, 42, 0.9);
      margin-top: 1.7em;
      &:before {
        content: none;
      }
    }
  }

  .bef-select-as-links {
    .form-item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      > a {
        .btn;
        .btn-primary;
        &.active {
          background-color: @brand-success;
        }
      }
    }
  }
}


.field-name-node-link {
  text-align: center;
  margin-top: 30px;
  a {
    .btn;
    .btn-success;
    color: #fff;
    &:hover, &:focus {
      color: #fff;
    }
  }
}


.node-type-product-group {
  .node-product-group {
    .field.field-name-title-field {
      text-align: center;
    }
  }
}


#block-views-after-sales-contacts-block {
  .container();
  text-align: center;
  margin-top: 100px;
  margin-bottom: 60px;
  h2 {
    margin-bottom: 45px;
  }
  .view-content {
    .views-bootstrap-grid-plugin-style {
      > .row {
        text-align: center;
        font-size: 0;
        > div {
          float: none;
          display: inline-block;
          text-align: left;
          font-size: @font-size-base;
        }
      }
    }

    .node-sales-contact-location {
      margin-bottom: 45px;
      text-align: left;
    }
  }
}

#block-bean-webshop,
#block-bean-service-partners {
  .panel;
  .panel-primary;
  padding: 15px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: @screen-sm-min){
    max-width: 50%;
    padding: 45px;
  }
  h2.block-title {
    font-size: 20px;
    @media (min-width: @screen-sm-min){
      font-size: 24px;
    }
  }

  .field-name-field-link {
    text-align: center;
    margin-top: 30px;
    .btn.btn-success, .btn.btn-primary {
      font-size: 16px;
      padding: 9px 10px;
      @media (min-width: @screen-sm-min){
        font-size: 16px;
        padding: 9px 30px;
      }
    }
  }
}

#floating-manager-menu {
  a {
    &:hover {
      color: black;
    }
  }
}

.tabs--primary.nav.nav-tabs {
  .container();
}

.region.region-navigation {
  @media (max-width: @grid-float-breakpoint-max){
    padding-top: 25px;
  }
  .block-menu-block {
    .pull-left;
    @media (min-width: @grid-float-breakpoint){
      .pull-right;
    }
  }

  .menu-block-wrapper {
    ul.menu {
      margin: 0;
      padding: 0;
      position: relative;
      display: block;
      @media (min-width: @grid-float-breakpoint){
        /*background: #002166;
        &:before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0px;
          right: -10px;
          height: 100%;
          background: #002166;
          -webkit-transform-origin: 100% 0;
          -ms-transform-origin: 100% 0;
          transform-origin: 100% 0;
          -webkit-transform: skew(@narko-angle);
          -ms-transform: skew(@narko-angle);
          transform: skew(@narko-angle);
          z-index: 1;
        }*/
        li.last {
          a {
            padding-right: 15px;
          }
        }
      }
      li a {
        color: #fff;
        @media (min-width: @grid-float-breakpoint){
          color:#002166;
        }
        display: block;
        position: relative;
        z-index: 2;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        margin-left: 20px;
        font-size: 12px;
        /*@media (max-width: @grid-float-breakpoint-max){
          text-indent: -9999px;
        }*/
        &.menu_icon {
          padding-left: 45px;
          padding-right: 20px;
        }
      }
    }
  }
}

.node-sales-contact-location {
  .field-name-field-contact-type {
    .field-items {
      .field-item {
        .btn;
        .btn-info;
        color: #fff;
        font-size: 12px;
        padding: 2px;
        margin: 5px 15px 5px;
        cursor: auto;
        &:hover, &:focus {
          color: #fff;
          &:before {
            background-color: @btn-info-bg;
          }
        }
      }
    }
  }
}

.webform-component {
  .form-submit {
    margin-top: 10px;
    font-size: 13px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px 10px;
  }
  .control-label {
    a {
      text-decoration: underline;
    }
  }
}

.field-name-field-attachments{
  margin-top: 30px;
  margin-bottom: 30px;
}
.node-vacancy {
  .field-name-field-image {
    margin-bottom: 30px;
  }
}

.node.node-after-sales-campaign.view-mode-full  {
  margin-top: 100px;
  margin-bottom: 100px;
}

#sliding-popup {
  .popup-content {
    #popup-buttons { display: flex; }
    .eu-cookie-compliance-categories-buttons button, #popup-buttons button {
      .btn;
      .btn-default;
      background-color: #2a66b1;
      border: 1px solid #fff;
      color: #fff;
      padding: 8px 25px;
      box-shadow: none;
      text-shadow: none;
      &:hover, &:focus, &:active, &:active:focus {
        background-color: #002166 !important;
        border-color: #002166 !important;
        color: #fff;
	outline: none;
      }
    }
    #popup-text {
      margin-top: 20px;
      a {
        text-decoration: underline;
      }
      p {
	font-size: 16px;
	font-weight: normal;
      }
    }
  }
}

.field-name-field-telephone,
.field-name-field-email,
.field-name-field-web {
  .field-label {
    font-weight: normal;
  }
}

#block-views-after-sales-campaigns-block {
  margin-bottom: 30px;
}

.field-name-field-address {
  .country-KA + span {
    visibility: hidden;
    position: relative;
      &:after {
        visibility: visible;
      	position: absolute;
      	top: 0;
      	left: 0;
      	content: "Finland";
      }
  }
}

#accordion.panel-group {
  @media (max-width: @screen-sm-max){
    padding-left: 0px;
    padding-right: 0px;
  }
}

.field.field-name-field-action-links {
  .field-items {
    .field-item {
      margin-bottom: 15px;
    }
  }
}

/* Youtube paragraph */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}

.videoWrapper iframe, .videoWrapper object, .videoWrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (min-width: @grid-float-breakpoint){
    pointer-events: none;
  }
}

